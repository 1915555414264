export const SIGNEDURL_BACKEND_ENDPOINT = 'https://d1cdbbnuzj7idz.cloudfront.net/prod/signurl'
export const CFSTATIC_BACKEND_ENDPOINT = 'https://d28nynx0ys6755.cloudfront.net/api'
export const DIRECTSTATIC_BACKEND_ENDPOINT = 'https://caching-backend.pgjaiganesh.space/api'
export const WATERMARK_IMAGE_ENDPOINT = "https://d296dlyyh7mn7k.cloudfront.net/"
export const CACHING_BACKEND_ENDPOINT = "https://d1qpr2b61meppc.cloudfront.net/"
export const NO_ATTEMPTS = [
    {
      "label": 25,
      "value": 25
    },
    {
        "label": 50,
        "value": 50
      },
      {
        "label": 75,
        "value": 75
      },

]
export const CACHE_NO_ATTEMPTS = 15
export const NO_ATTEMPTS_LARGEFILES = 10
export const PERFORMANCE_ENTRIES = 600
export const PRICING_BACKEND_ENDPOINT = 'https://d1d6kxn3oboaih.cloudfront.net'
export const CMCD_STREAM_ENDPOINT = 'https://d1dsrp45dqcxe6.cloudfront.net/video/hls/buck_bunny/Big_BuckBunny_5mins.m3u8'
// export const INSPECT_REQUEST_ENDPOINT = "https://integrationpointsstack-us-east-1-cf.demos.edge.aws.dev/"
export const INSPECT_REQUEST_ENDPOINT = "https://d166yjzzba2lk5.cloudfront.net/"
export const FEEDBACK_ENDPOINT = 'https://d1d6kxn3oboaih.cloudfront.net'
export const WAF_DASHBOARD_ENDPOINT = "https://search-osdfw-opensearch-domain-evjwzsrpaygh7jpb3tb53rvzgi.us-east-1.es.amazonaws.com/_dashboards/app/dashboards#/view/main-dashboard?_g=(filters%3A!()%2CrefreshInterval%3A(pause%3A!t%2Cvalue%3A10000)%2Ctime%3A(from%3Anow-1d%2Cto%3Anow))"
// export const CDN_FINDER_ENDPOINT = "https://d2u8wb9u3zuxnh.cloudfront.net/"
export const CDN_FINDER_ENDPOINT = "https://d1bg8nzecy8ghc.cloudfront.net/"
export const WAFCOMMON_ENDPOINT = "https://d3az5q5lpqfw4v.cloudfront.net"
export const ORIGINFAILOVER_ENDPOINT = "https://d3ftutdm0kks82.cloudfront.net"
export const WAFATP_ENDPOINT = "https://d23hu88hzopab6.cloudfront.net"
export const MULTI_CDN_ENDPOINT = "https://g-286a68fcac.grafana-workspace.us-east-1.amazonaws.com/d/1CuRJMKVz/cdn-comparison-performance-metrics?orgId=1"
export const CF_RUM_ENDPOINT = "https://g-286a68fcac.grafana-workspace.us-east-1.amazonaws.com/d/FL-7Nz5Vk2/observability-website-performance?orgId=1"
export const SIMPLE_CHANNEL_ENDPOINT = "https://dl0aiy1r4giyd.cloudfront.net/"
export const DEMOPORTAL_USAGE_ENDPOINT = "https://g-e700501c45.grafana-workspace.us-east-1.amazonaws.com/d/Lkpvpv24z/demo-portal-usage-stats?orgId=1"
export const CONTENT_DISCOVERY_ENDPOINT = "https://l231lapeg5.execute-api.us-east-1.amazonaws.com/"
export const EDGE_CONFIGURATOR_ENDPOINT = "https://d3v4yk0ezmc1sa.cloudfront.net/"
// export const EDGE_CONFIGURATOR_ENDPOINT = "http://127.0.0.1:8000"
// export const TREND_ANALYSIS_ENDPOINT = "http://127.0.0.1:8000"
export const TREND_ANALYSIS_ENDPOINT = "https://dr1axgksl7fhs.cloudfront.net/"
export const MEDIA_CONTENT_DISCOVERY_ENDPOINT = "https://gd580g5zp8.execute-api.us-east-1.amazonaws.com/"
export const INSIGHTS_ANALYSIS_ENDPOINT = "http://localhost:8000/"
// export const INSIGHTS_ANALYSIS_ENDPOINT = "https://d381hw07vt3ndd.cloudfront.net"