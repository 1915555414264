
import {
  SIGNEDURL_BACKEND_ENDPOINT,
  CACHING_BACKEND_ENDPOINT,
  WATERMARK_IMAGE_ENDPOINT,
  PRICING_BACKEND_ENDPOINT,
  CONTENT_DISCOVERY_ENDPOINT,
  MEDIA_CONTENT_DISCOVERY_ENDPOINT,
} from '../resources/prod-env.jsx';

// import {
// } from '../resources/form-config.jsx';

const qs = require('querystring-es3');
// let auth = "eyJraWQiOiJOTG9pcHJxQndUdklFa3FrNDgwTTNaVGxncHphUlwvUkpoQTZaQ0J5UWdSVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhOWFiYTczOS1jZjFjLTQyNGEtOThiOC03NmNhYTE5MzI2NWIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfdlhoOHVvMVVOX0F1dGhlbnRpY2F0aW9uU3RQcm92aWRlcjA1QTQxQUJBIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3ZYaDh1bzFVTiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6Img0YmZtNjc3dmJxYWRudWw1ZTQ5dDRmc2EiLCJvcmlnaW5fanRpIjoiYWU4YzhjYTYtOWMzZi00MzRjLThlZjItY2ZkZDk0OWRiM2M0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTcxMDUwODQyOSwiZXhwIjoxNzEwNTk0ODI5LCJpYXQiOjE3MTA1MDg0MjksImp0aSI6ImE0NWM2ZDU4LWUyMzctNDIxMy05NGI4LTkzMDBiODI1ZDg5ZSIsInVzZXJuYW1lIjoiQXV0aGVudGljYXRpb25TdFByb3ZpZGVyMDVBNDFBQkFfZ2FuZXNoamkifQ.NSmDHRBpJ4Zc4tGfb2dkKZQcuZYBQYmAY3eBWIJjJoZ6WdBuEqWnZq6w-Pe7spjW4USkFo_OBWw2HO3cE_mi4A45YmkGbZVxWQEsfVpgtqWwah1Fj7R47Au8BbMsiWGTwGqwUxvPRbg78ihQm3QzzfLuL-DdrRHK8CSyEKI1s1-r8FdG4fNO7B5KEAOZ6bCgzXprP5HstwA9-s4wPbuQr1BcbbdoIWTz5DdYxMLOeXB8VpeEhrGQ0xnPXsHXzVOF_YMszYO_LhYzHqapEr4RJmkrVs02B-1Qkr3uM-Xfl5Ying_BKpPA__fDcIWproN1pFiPjJWPeeRvgLVZYpFBXg";
// auth = 'eyJraWQiOiJOTG9pcHJxQndUdklFa3FrNDgwTTNaVGxncHphUlwvUkpoQTZaQ0J5UWdSVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhOWFiYTczOS1jZjFjLTQyNGEtOThiOC03NmNhYTE5MzI2NWIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfdlhoOHVvMVVOX0F1dGhlbnRpY2F0aW9uU3RQcm92aWRlcjA1QTQxQUJBIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3ZYaDh1bzFVTiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6Img0YmZtNjc3dmJxYWRudWw1ZTQ5dDRmc2EiLCJvcmlnaW5fanRpIjoiNGY0NzFmZGMtZTFjNi00NGFjLTkxYTItMDNkMDlkNGM2ZjQ1IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTcxMDg4MzQ2NCwiZXhwIjoxNzEwOTY5ODY0LCJpYXQiOjE3MTA4ODM0NjQsImp0aSI6IjM1ODQ5Y2QxLTU1ZWItNDk1My04YzMwLTMwMzI0MGJiNGE1OCIsInVzZXJuYW1lIjoiQXV0aGVudGljYXRpb25TdFByb3ZpZGVyMDVBNDFBQkFfZ2FuZXNoamkifQ.oZnMWZgjbj5Z8x7b6ULIST2QYpswfUNtEiltmhXQf7cpH8p3VTvNcCf2vTi5NHYqviAvgibYTdw1fe4atjVySAda7hlFCeQgRY7_3apz13o3KZjTP9wJx6UoveUY1Evx_v9E3yK3fK_APhQplKAZb1y9vSuPfHJVKlkengAZmMgWDP5LRHyfexG14NUKU7ycww-_FN0YG78NB8TUxPL8Ja8iToHxVJ0jRIt6v90H9Zk9RwhAdcyv9FfQwAarbOkwMGB1tlXJFmGoxy7LpS1KyrSLC3sqOUavyfTPdefX0KKWobXyn8v17wzgI_xwNrM0ybx52LvaiXNqvtL4AA25Ew';
export default class DataProvider {

  getData = (name, callback) => {
    try {
      fetch(
        `./${name}.json`
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          return callback(response);
        });
    } catch (error) {
      console.error(error);
    }
  }

  getAPIData = (url, callback) => {
    try {
      fetch(
        `${url}`
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          return callback(response);
        });
    } catch (error) {
      console.error(error);
    }
  }
  getPricingData = (serviceCode, regionLocationCode, callback) => {
    try {
      fetch(
        `${PRICING_BACKEND_ENDPOINT}/pricing/${serviceCode}/${regionLocationCode}/data.json`
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          return callback(response);
        });
    } catch (error) {
      console.error(error);
    }
  }

  getSignedData(params, callback) {

    params.qs = qs.stringify(params.qs);
    try {
      fetch(
        SIGNEDURL_BACKEND_ENDPOINT, {
        method: 'POST',
        // credentials: 'include',
        headers: {
        },
        body: JSON.stringify(params)
      }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          return callback(response);
        });
    } catch (error) {
      console.error(error);
    }
  }

  listContentTypes = (params, callback) =>{
    // params['Authorization'] = auth;
    // let queryString = qs.stringify(params);
    let auth = params['Authorization']
    delete params['Authorization'];
    // console.log(queryString);
    try {
      fetch(
        // CONTENT_DISCOVERY_ENDPOINT+'list?'+queryString, {
      CONTENT_DISCOVERY_ENDPOINT+'list', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': auth
        },
        // headers: {
        //   'Authorization':'eyJraWQiOiJOTG9pcHJxQndUdklFa3FrNDgwTTNaVGxncHphUlwvUkpoQTZaQ0J5UWdSVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhOWFiYTczOS1jZjFjLTQyNGEtOThiOC03NmNhYTE5MzI2NWIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfdlhoOHVvMVVOX0F1dGhlbnRpY2F0aW9uU3RQcm92aWRlcjA1QTQxQUJBIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3ZYaDh1bzFVTiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6Img0YmZtNjc3dmJxYWRudWw1ZTQ5dDRmc2EiLCJvcmlnaW5fanRpIjoiMTIwNWY5YzQtNGNjMi00MzIxLWFmM2EtZTg3NWZiMzM3MWMxIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTcxMDQyMzEzMCwiZXhwIjoxNzEwNDI2NzMwLCJpYXQiOjE3MTA0MjMxMzAsImp0aSI6ImU2ZDgyNTQyLWQyYTMtNGUxNi1hMGE3LTNiOGJhM2Y2MDQxMSIsInVzZXJuYW1lIjoiQXV0aGVudGljYXRpb25TdFByb3ZpZGVyMDVBNDFBQkFfZ2FuZXNoamkifQ.i0K1nE278Jvk40NHhu9XYvg7JG5COww6-15-we2n6jzGp_uEeFogjkCJIhnWCqMiJE_HOsUxKvQttuUtviv3aGubRamzQww_mUuVr1M4BUgvXqQjQk-If0OgFmCyEyIzafyiwU3AhmoF5Rg1FsaTK2fXpZMG6Zlv2pfuS4rfv1hYZE0VQ7s9PO_UHFcCtF6ovLhMqe3bU83TsfKbXxryz29xdquRJ5dCc5YnUof-k0bbRlY7U6Ul2_kItj4HbwdyvspjUuIzCD8DynlaWuqYrD9uLJ8ezYKyQAn6sYKKLubpag_kqo67613GVqEXAbJ9SQnghEixN1lVpXryiSXMvw'
        // }
        // body: JSON.stringify(params)
      }
      )
        .then((response) => {
          // console.log(response.json());
          response.json().then((response) => {
          // console.log(response);
          return callback(response);
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  listQuestions = (params, callback) =>{
    // params['Authorization'] = auth;
    // let queryString = qs.stringify(params);
    let auth = params['Authorization']
    delete params['Authorization'];
    // console.log(queryString);
    try {
      fetch(
        // CONTENT_DISCOVERY_ENDPOINT+'questions?'+queryString, {
        CONTENT_DISCOVERY_ENDPOINT+'questions', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Authorization': auth
        },
        // headers: {
        //   'Authorization':'eyJraWQiOiJOTG9pcHJxQndUdklFa3FrNDgwTTNaVGxncHphUlwvUkpoQTZaQ0J5UWdSVT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJhOWFiYTczOS1jZjFjLTQyNGEtOThiOC03NmNhYTE5MzI2NWIiLCJjb2duaXRvOmdyb3VwcyI6WyJ1cy1lYXN0LTFfdlhoOHVvMVVOX0F1dGhlbnRpY2F0aW9uU3RQcm92aWRlcjA1QTQxQUJBIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX3ZYaDh1bzFVTiIsInZlcnNpb24iOjIsImNsaWVudF9pZCI6Img0YmZtNjc3dmJxYWRudWw1ZTQ5dDRmc2EiLCJvcmlnaW5fanRpIjoiMTIwNWY5YzQtNGNjMi00MzIxLWFmM2EtZTg3NWZiMzM3MWMxIiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiBwaG9uZSBvcGVuaWQgcHJvZmlsZSBlbWFpbCIsImF1dGhfdGltZSI6MTcxMDQyMzEzMCwiZXhwIjoxNzEwNDI2NzMwLCJpYXQiOjE3MTA0MjMxMzAsImp0aSI6ImU2ZDgyNTQyLWQyYTMtNGUxNi1hMGE3LTNiOGJhM2Y2MDQxMSIsInVzZXJuYW1lIjoiQXV0aGVudGljYXRpb25TdFByb3ZpZGVyMDVBNDFBQkFfZ2FuZXNoamkifQ.i0K1nE278Jvk40NHhu9XYvg7JG5COww6-15-we2n6jzGp_uEeFogjkCJIhnWCqMiJE_HOsUxKvQttuUtviv3aGubRamzQww_mUuVr1M4BUgvXqQjQk-If0OgFmCyEyIzafyiwU3AhmoF5Rg1FsaTK2fXpZMG6Zlv2pfuS4rfv1hYZE0VQ7s9PO_UHFcCtF6ovLhMqe3bU83TsfKbXxryz29xdquRJ5dCc5YnUof-k0bbRlY7U6Ul2_kItj4HbwdyvspjUuIzCD8DynlaWuqYrD9uLJ8ezYKyQAn6sYKKLubpag_kqo67613GVqEXAbJ9SQnghEixN1lVpXryiSXMvw'
        // }
        // body: JSON.stringify(params)
      }
      )
        .then((response) => {
          // console.log(response.json());
          response.json().then((response) => {
          // console.log(response);
          return callback(response);
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  search = (params, callback) =>{
    // params['Authorization'] = auth;
    // let queryString = qs.stringify(params);
    let auth = params['Authorization']
    delete params['Authorization'];
    // console.log(queryString);
    try {
      fetch(
        // CONTENT_DISCOVERY_ENDPOINT+'search?'+queryString, {
        CONTENT_DISCOVERY_ENDPOINT+'search', {
          method: 'POST',
          mode: 'cors',
          headers: {
          'Authorization': auth
        },
        body: JSON.stringify(params)
      }
      )
        .then((response) => {
          // console.log(response.json());
          response.json().then((response) => {
          // console.log(response);
          return callback(response);
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  mediasearch = (params, callback) =>{
    // params['Authorization'] = auth;
    // let queryString = qs.stringify(params);
    let auth = params['Authorization']
    delete params['Authorization'];
    // console.log(queryString);
    try {
      fetch(
        // CONTENT_DISCOVERY_ENDPOINT+'search?'+queryString, {
        MEDIA_CONTENT_DISCOVERY_ENDPOINT+'search', {
          method: 'POST',
          mode: 'cors',
          headers: {
          'Authorization': auth
        },
        body: JSON.stringify(params)
      }
      )
        .then((response) => {
          // console.log(response.json());
          response.json().then((response) => {
          // console.log(response);
          return callback(response);
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  checkIdea = (params, callback) =>{
    // params['Authorization'] = auth;
    // let queryString = qs.stringify(params);
    let auth = params['Authorization']
    delete params['Authorization'];
    // console.log(queryString);
    try {
      fetch(
        // CONTENT_DISCOVERY_ENDPOINT+'search?'+queryString, {
        CONTENT_DISCOVERY_ENDPOINT+'checkidea', {
          method: 'POST',
          mode: 'cors',
          headers: {
          'Authorization': auth
        },
        body: JSON.stringify(params)
      }
      )
        .then((response) => {
          // console.log(response.json());
          response.json().then((response) => {
          // console.log(response);
          return callback(response);
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  getCFData = (params, url, callback, dataProvider, attrs) => {
    if (params) {
      url = `${url}?${qs.stringify(params)}`;
    }
    let start = performance.now();

    try {
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache'
        },
      }
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = Math.round(performance.now() - start);
          if (attrs) {
            details.params = attrs;
          }
          details.url = url;
          details.status = response.status;
          // details.body = response.json();
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          dataProvider.populateResponseHeaders(details, headers);
          // performance.mark('endSquareRootLoop');
          return callback(details);
        });
    } catch (error) {
      let details = {};
      details.status = 403;
      details.time = Math.round(performance.now() - start);
      if (attrs) {
        details.params = attrs;
      }
      details.url = url;
      details.status = 403;
      console.error(error);
      return callback(details);
    }
  };

  getCacheData = (params, url, callback, dataProvider, attrs,cacheSetting='default') => {
    if (params) {
      url = `${url}?${qs.stringify(params)}`;
    }
    let start = performance.now();

    try {
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'GET',
        // cache: 'default',
        mode: 'cors',
        headers: {
          'pragma': cacheSetting,
          'cache-control': cacheSetting
        },
      }
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = Math.round(performance.now() - start);
          if (attrs) {
            details.params = attrs;
          }
          details.url = url;
          details.status = response.status;
          // details.body = response.json();
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          dataProvider.populateResponseHeaders(details, headers);
          // performance.mark('endSquareRootLoop');
          return callback(details);
        });
    } catch (error) {
      let details = {};
      details.status = 403;
      details.time = Math.round(performance.now() - start);
      if (attrs) {
        details.params = attrs;
      }
      details.url = url;
      details.status = 403;
      console.error(error);
      return callback(details);
    }
  };

  getWAFCommonData = (params, requestHeaders, url, callback, dataProvider) => {
    if (params) {
      url = `${url}?${qs.stringify(params)}`;
    }

    try {
      let start = performance.now();
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache',
          ...requestHeaders
        },
      }
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = Math.round(performance.now() - start);
          if (requestHeaders) {
            details.requestHeaders = requestHeaders;
          }
          details.status = response.status;
          details.url = url;
          // details.body = response.json();
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          if (headers.get('x-waf-custom-header')) {
            details.responseHeaders = {
              'x-waf-custom-header': headers.get('x-waf-custom-header')
            };
          }
          
          if(response.ok) {  
            response.json().then(payload => {
              details.response = payload;
            }).finally(() => {
              return callback(details);
            });
          } else {
            response.text().then(payload => {
              details.response = payload;
            }).finally(() => {
              return callback(details);
            });
          }
          // performance.mark('endSquareRootLoop');
          // return callback(details);
        });
    } catch (error) {
      console.error(error);
      callback(error);
    }
  };

  postWAFCommonData = (payload, requestHeaders, url, callback, dataProvider) => {
    // if (params) {
    //   url = `${url}?${qs.stringify(params)}`;
    // }

    const cookies = document.cookie.split(';');
    let wafCookie = null;
    for (let i = 0; i < cookies.length; i++) {
      // console.log(cookies[i]);
      if (cookies[i].includes('aws-waf-token')) {
        wafCookie = cookies[i];
        // console.log(cookies[i].split("=")[1]);
        //.. Do what you want with connect.sid cookie
        break;
      }
    }

    try {
      let start = performance.now();
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify(payload),
        mode: 'cors',
        // credentials:'include',
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache',
          'Cookie': wafCookie,
          ...requestHeaders
        },
      }
      )
        .then((response) => {
          const headers = response.headers;
          console.log("headers :%j",response.status);
          let details = {};
          details.time = Math.round(performance.now() - start);
          details.requestBody = payload;
          if (requestHeaders) {
            details.requestHeaders = requestHeaders;
          }
          details.url = url;
          // details.body = response.json();
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          details.responseHeaders={
            'status':response.status
          };
          if (headers.get('x-success')) {
            details.responseHeaders['x-success']= headers.get('x-success');
          }
          if (headers.get('x-waf-custom-header')) {
            details.responseHeaders['x-waf-custom-header']= headers.get('x-waf-custom-header');
          }
          response.json().then(payload => {
            details.response = payload;
          }).finally(() => {
            return callback(details);
          });
          // performance.mark('endSquareRootLoop');
          // return callback(details);
        });
    } catch (error) {
      console.error(error);
    }
  };

  getCDNInfo = (body, url, callback, dataProvider, attrs) => {

    try {
      let start = performance.now();
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'GET',
        // body: body,
        // cache: 'no-cache',
        mode: 'cors',
        // keepalive: false,
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache',
          'cdn-for-hash': body,
        },
      }
      )
        .then((response) => {
          const headers = response.headers;

          let details = {};
          details.time = Math.round(performance.now() - start);
          if (attrs) {
            details.params = attrs;
          }
          details.url = url;
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          dataProvider.populateResponseHeaders(details, headers);
          response.json().then(payload => {
            details.response = payload;
            return callback(details);
          });
          // performance.mark('endSquareRootLoop');
        });
    } catch (error) {
      console.error(error);
    }
  };

  postCFData = (body, url, callback, dataProvider, attrs) => {
    let start = performance.now();

    try {
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'POST',
        body: body,
        // cache: 'no-cache',
        mode: 'cors',
        // keepalive: false,
        // headers: {
        //   'pragma': 'no-cache',
        //   'cache-control': 'no-cache'
        // },
      }
      )
        .then((response) => {
          const headers = response.headers;

          let details = {};
          details.time = Math.round(performance.now() - start);
          if (attrs) {
            details.params = attrs;
          }
          details.url = url;
          details.status = response.status;
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          dataProvider.populateResponseHeaders(details, headers);
          // performance.mark('endSquareRootLoop');
          return callback(details);
        });
    } catch (error) {
      console.error(error);

      let details = {};
      details.time = Math.round(performance.now() - start);
      if (attrs) {
        details.params = attrs;
      }
      details.url = url;
      details.status = 403;
      return callback(details);
    }
  };

  getInspectData = (params, url, callback, dataProvider, attrs) => {
    // if (params) {
    //   url = `${url}?${qs.stringify(params)}`;
    // }

    try {
      let start = performance.now();
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'GET',
        cache: 'no-cache',
        mode: 'cors',
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache'
        },
      }
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = Math.round(performance.now() - start);
          if (attrs) {
            details.params = attrs;
          }
          details.url = url;
          dataProvider.populateResponseHeaders(details, headers);
          details.ores_event = headers.get('ores_event');
          details.vres_event = headers.get('vres_event');

          response.json().then((response) => {
            console.log("json :%j", response);
            details.oreq_event = response.headers.oreq_event;
            details.vreqqs_event = response.headers.vreqqs_event;
            details.oreqqs_event = response.queryStringParameters;
            details.vreqh_event = response.headers.vreqh_event;

            return callback(details);
          });
        });
    } catch (error) {
      console.error(error);
    }
  };

  getSignedURLData = (params, url, cookies, callback, dataProvider) => {
    let queryString = qs.stringify(params);
    // document.cookies = "test1=2";
    try {
      // let start = performance.now();
      fetch(
        // url + "?" + queryString, {
        url, {
        method: 'HEAD',
        cache: 'no-cache',
        mode: 'cors',
        // credentials: 'include',
        headers: {
          'pragma': 'no-cache',
          'cache-control': 'no-cache',
          // 'set-cookie': 'test1=value1',
        },
        // cookies: 'test=1'
      }
      )
        .then((response) => {
          const headers = response.headers;

          let details = {};
          details.time = new Date().toLocaleString();
          if (params) {
            details.params = params;
          }
          details.status = response.status;
          details.url = url;
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          dataProvider.populateResponseHeaders(details, headers);
          // performance.mark('endSquareRootLoop');
          return callback(details);
        })
        .catch((response) => {
          // console.log("Response denied:");

          let details = {};
          details.time = new Date().toLocaleString();
          details.status = 403;
          if (params) {
            details.params = params;
          }
          details.url = url;
          // details.body_length = response.size;
          // console.log("Body length ", headers.get('content-encoding'));
          // performance.mark('endSquareRootLoop');
          return callback(details);
        });
    } catch (error) {
      console.log("Response error");

      console.error(error);
    }
  };

  fetchData = (mode, url, params, body, callback, dataProvider) => {
    if (mode === "APIsPOST") {
      return this.postCFData(body, url, callback, dataProvider, params);
    }
    return this.getCFData(null, url, callback, dataProvider, params);
  };

  getCachingData = (params, callback, dataProvider) => {
    try {
      let queryString = qs.stringify(params);
      let start = new Date();
      fetch(
        CACHING_BACKEND_ENDPOINT + "?" + queryString
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = (new Date()) - start;
          dataProvider.populateResponseHeaders(details, headers);
          return callback(details);
        });
    } catch (error) {
      console.error(error);
    }
  }

  getWatermarkedImage = (params, callback, dataProvider) => {
    // console.log("in dataprovider");
    // console.log(params.image);

    try {
      fetch(
        //WATERMARK_IMAGE_ENDPOINT+"testImage_watermark.jpeg&logo=logo.png"
        WATERMARK_IMAGE_ENDPOINT + "images/" + params.image + "&logo=logos/" + params.logo
      )
        .then((response) => response.blob())
        .then(image => {
          //console.log(response)
          //console.log(response.blob())
          //const image = response.data
          //console.log(image)
          //const imageObjectURL = URL.createObjectURL(image)
          //console.log(imageObjectURL)
          return callback(image)
        })
    } catch (error) {
      console.error(error);
    }
  }

  getDirectData = (url, callback, dataProvider) => {
    try {
      let start = new Date().getTime();
      fetch(
        url
      )
        .then((response) => {
          const headers = response.headers;
          let details = {};
          details.time = (new Date().getTime()) - start;
          details.length = headers.get('content-length');
          return callback(details);
        });
    } catch (error) {
      console.error(error);
    }
  }

  populateResponseHeaders = (details, headers) => {
    // console.log(headers.entries());
    // for (const pair of headers.entries()) {
    //   console.log(`${pair[0]}: ${pair[1]}`);
    // }
    // details.status = headers.get("status");
    details.age = headers.get('age');
    details.pop = headers.get('x-amz-cf-pop');
    details.cache = headers.get('x-cache');
    details.compression = headers.get('content-encoding');
    let contentLength = headers.get('content-length');
    details.content_length = contentLength ? parseInt(contentLength) : 0;
    return details;
  }
}