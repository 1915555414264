/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

  https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import DataProvider from '../../resources/data-provider';
import ServiceNavigation from '../ServiceNavigation.jsx';
import CFTablePanel from '../CFTablePanel.jsx';
import CFTableTools from '../CFTableTools.jsx';
import ExtendingPanel from '../ExtendingPanel.jsx';
import SynopsisPanel from '../SynopsisPanel';
import TopPanel from '../TopPanel';
import BottomPanel from '../BottomPanel';
import BrowserCheck from '../BrowserCheck';
import {
  CACHING_BACKEND_ENDPOINT, CACHE_NO_ATTEMPTS,
} from '../../resources/prod-env.jsx';

import {
  AppLayout,
  Button,
  Form,
  FormField,
  Select,
  Grid,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Icon,
  ColumnLayout,
  Alert,
  Checkbox
} from '@cloudscape-design/components';

import '../../styles/form.scss';
import { sendAnalytics } from '../../resources/rum-provider.ts';
const demoModule = "Caching";

// Class CreateForm is a skeleton of a Single page create form using AWS-UI React components.
export default class Caching extends React.Component {

  constructor(props) {
    super(props);
    this.cfresults = [];
    this.state = {
      toolsIndex: 0, toolsOpen: false, cfresults: [],
      cfresults_stats: [], results: [], currentResult: {}, alert: false,
      disableBrowerCache:{ checked: true },
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.dataProvider = new DataProvider();
  }
  componentDidMount() {
    //dataProvider.getData('content-origins', contentOrigins => this.setState({ contentOrigins: contentOrigins }));
  }

  onInputChange(name, e) {
    e.preventDefault();
    // console.log("In onInputChange %s %j", name, e);
    if (name === 'disableBrowerCache') {
      this.setState({ [name]: e.detail });
    }else{
    this.setState({ [name]: e.detail.selectedOption });
    }
  }

  handleClick(e) {
    e.preventDefault();
    if (!this.state.selectedOption || !this.state.cachePolicyOption) {
      // console.log("Region not selected");
      this.setState({ alert: true });
      return;
    }
    else {
      sendAnalytics({ demo: demoModule });
      this.handleAlert();
    } this.refreshCFPanel(e);
    // console.log("State :%j", this.state);
  }

  refreshCFPanel(e) {
    // this.state.cfresults = [];
    let items = [];
    let selectedOption = this.state.selectedOption;
    let cachePolicyOption = this.state.cachePolicyOption;
    let url = `${CACHING_BACKEND_ENDPOINT}${cachePolicyOption.path}/${selectedOption.value}`;
    let disableBrowserCache=this.state.disableBrowerCache.checked?'no-cache':'default';
    console.log(disableBrowserCache);

    let interval = setInterval(() => {
      if (items.length < CACHE_NO_ATTEMPTS) {

        this.dataProvider.getCacheData({ cc: selectedOption.value }, url, response => {
          items.push(response);
          this.setState({
            currentResult: {
              label: `${cachePolicyOption.label},${selectedOption.value}`, results: items, url: url, time: Date.now()
            }
          });
        }, this.dataProvider,null,disableBrowserCache);
      } else {
        clearInterval(interval);
        this.cfresults.unshift({ label: `${cachePolicyOption.label},${selectedOption.value}`, results: items, url: url, time: new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(Date.now()) });
        this.setState({ cfresults: this.cfresults });
        // this.state.cfresults.push(items);
        // this.setState({cfresults:})
        // console.log("Calculating perentile");
        // calculatePercentile(items);
      }
    }, 700);
  }

  handleAlert() {
    // console.log("In handleAlert");
    this.setState({ alert: false });
  }

  render() {
    return (
      <React.Fragment>
        <TopPanel label={demoModule} />
        <AppLayout
          navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
          // breadcrumbs={<Breadcrumbs items={BreadcrumbsItems} />}
          content={
            <Content
              // Changes the Help panel content when the user clicks an 'info' link
              replaceToolsContent={index => this.setState({ toolsIndex: index, toolsOpen: true })}
              handleClick={this.handleClick}
              handleAlert={this.handleAlert}
              currentState={this.state}
              onInputChange={this.onInputChange}
              update={this.update}
            />
          }
          contentType="default"
          tools={Tools[this.state.toolsIndex]}
          onToolsChange={({ detail }) => this.setState({ toolsOpen: detail.open })}
          toolsOpen={this.state.toolsOpen}
        />
        <BottomPanel label="Caching" />
      </React.Fragment>
    );
  }
}

// The content in the main content area of the App layout
const Content = props => {
  const [
    selectedOption,
    setSelectedOption
  ] = React.useState({
    label: "max-age=5",
    value: "max-age=5",
    path: "maxage5",
    description: "Cache both on client side and CloudFront for a maximum time of 5 sec"
  });

  return (
    <React.Fragment>
      <BrowserCheck />
      <SpaceBetween direction='vertical' size='l'>
        <SynopsisPanel label="Synopsis" videolink="https://broadcast.amazon.com/videos/678807">
          CloudFront honors 'Cache-Control' headers sent from origin. The cache time also depends on
          the Cache Policy defined and what range is specified as part of Min TTL,Max TTL and Default TTL.
          For this demo we use 2 managed cache policies.
        </SynopsisPanel>
        <Container>
          <form onSubmit={props.handleClick}>
            <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Alert type='error'
                    visible={props.currentState.alert}
                    onDismiss={() => props.handleAlert()}
                    // dismissAriaLabel={<Box variant='strong'>Close alert</Box>}
                    dismissible
                  // header={<Box variant='p'>Select AWS Region</Box>}
                  >
                    <Box variant='small'>Select 'Cache Policy and Cache Control header' fields and hit 'Test'</Box>
                  </Alert>
                  <Button variant="primary">Test</Button>
                </SpaceBetween>
              }
            >
              <Grid
                gridDefinition={[
                  { colspan: 3 },
                  { colspan: 3 }
                ]}
              >
                <FormField label="Cache Policy" info={
                  <Link variant="info" onFollow={() => props.replaceToolsContent(18)}>
                    Info
                  </Link>
                }>
                  <Select
                    selectedOption={props.currentState.cachePolicyOption}
                    onChange={props.onInputChange.bind(this, 'cachePolicyOption')}
                    options={[
                      {
                        label: "Managed:Cache Optimized",
                        value: "cacheoptimized",
                        path: "cacheoptimized",
                        description: "This policy is designed to optimize cache efficiency by minimizing the values that CloudFront includes in the cache key."
                      },
                      {
                        label: "Managed:Cache Disabled",
                        value: "cachedisabled",
                        path: "cachedisabled",
                        description: "This policy disables caching. This policy is useful for dynamic content and for requests that are not cacheable."
                      },
                    ]}
                    filteringType="auto"
                  />
                </FormField>
                <FormField label="Cache-Control header" info={
                  <Link variant="info" onFollow={() => props.replaceToolsContent(0)}>
                    Info
                  </Link>
                }>
                  <Select
                    selectedOption={props.currentState.selectedOption}
                    onChange={props.onInputChange.bind(this, 'selectedOption')}
                    options={[
                      {
                        label: "max-age=5",
                        value: "max-age=5",
                        path: "maxage5",
                        description: "Cache both on client side and CloudFront for a maximum time of 5 sec"
                      },
                      {
                        label: "s-maxage=5",
                        value: "s-maxage=5",
                        path: "smaxage5",
                        description: "Cache on CloudFront only for a maximum time of 5 sec"
                      },
                      {
                        label: "max-age=5, s-maxage=7",
                        value: "max-age=5, s-maxage=7",
                        path: "maxage5smaxage7",
                        description: "Client side cache for 5 secs, Cache on CloudFront for a maximum time of 7 sec"
                      },
                      {
                        label: "no-cache",
                        value: "no-cache",
                        path: "nocache",
                        description: "Don't cache it in CloudFront"
                      },
                    ]}
                    filteringType="auto"
                  />
                </FormField>
                <FormField label="Disable Brower Cache" info={
                <Link variant="info" onFollow={() => props.replaceToolsContent(2)}>
                    Info
                </Link>
            }>
                <Checkbox
                    onChange={props.onInputChange.bind(this, 'disableBrowerCache')}
                    checked={props.currentState?.disableBrowerCache?.checked}
                />
            </FormField>
              </Grid>
            </Form>
          </form>
        </Container>
        <Container header={<Box variant="strong">Current run</Box>}>
          <SpaceBetween direction='vertical' size='xxs'>
            {props.currentState.currentResult?.label && <Box variant="code">{props.currentState.currentResult.label}
              {/* <Link external href={props.currentState.currentResult.url} /> */}
            </Box>}
            <CFTablePanel results={props.currentState.currentResult.results} replaceToolsContent={props.replaceToolsContent} />
          </SpaceBetween>
        </Container>
        {props.currentState.cfresults.length > 1 && <Box variant="strong">Previous runs</Box>}
        <CFPreviewPanel currentState={props.currentState} replaceToolsContent={props.replaceToolsContent} />
        <ExtendingPanel label="Extend it..">
          <ColumnLayout columns={2} variant='text-grid' className='extend_it'>
            <Box>
              Questions/ideas on this demo?</Box>
            <Box variant='strong'>
              Jaiganesh Girinathan,
              ganeshji@amazon.com
            </Box>
          </ColumnLayout>
        </ExtendingPanel>
      </SpaceBetween >
    </React.Fragment >
  );
};

const CFPreviewPanel = props => {
  return (
    props.currentState.cfresults.map(function (item, index) {
      if (index != 0) {
        return <ExtendingPanel key1={`o${index}`} variant='container' label={<Header actions={<Box variant='code'>{item.time}</Box>} variant='h3'><Box variant='code'>Result: {item.label}
          {/* <Link external href={item.url} /> */}
        </Box></Header>}>
          <CFTablePanel key1={`i${index}`} results={item.results} replaceToolsContent={props.replaceToolsContent} />
        </ExtendingPanel >;
      }
    })
  )
};

const ResponseHeaderPanel = props => {
  return (
    <FormField
      label=""
      info={<div><pre>{JSON.stringify(JSON.parse(props.policy), null, 2)}</pre></div>}
      stretch={true}
    >
    </FormField>
  );
};

// List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
const Tools = [
  <HelpPanel
    header={<h2>Cache Control Header</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Expiration.html" target="_blank">
              Managing how long content stays in the cache (expiration)
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      CloudFront honors the cache control headers sent from origin. You can set the <code>Cache-Control</code> headers to specify how long
      content stays in the cache.
    </p>
  </HelpPanel>,
  ...CFTableTools,
  <HelpPanel header={<h3>Cache Policy</h3>} loadingText="index18"
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <Link external href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/working-with-policies.html">
              Working with policies
            </Link>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      With a CloudFront cache policy, you can specify the HTTP headers, cookies, and query strings that CloudFront includes in the cache key. The cache key determines whether a viewer’s HTTP request results in a cache hit (the object is served to the viewer from the CloudFront cache). Including fewer values in the cache key increases the likelihood of a cache hit.

      You can also use the cache policy to specify time to live (TTL) settings for objects in the CloudFront cache, and enable CloudFront to request and cache compressed objects.
    </p>
  </HelpPanel>,
];
